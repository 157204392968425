@import 'normalize.css/normalize.css';

body {
  overflow: hidden;
  background: slategrey;
}

#player, #sldpplayer {
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

#qrcode {
  position: fixed;
  right: 1em;
  bottom: 1em;
  border-radius: .5rem;
  width: 180px;
  height: 180px;
  background: #fff;
  z-index: 999999999;
}